import React, { useEffect } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"

import Grid from "../components/grid/Grid"
import ProductGrid from "../components/grid/schema/ProductGrid"
import StoryGrid from "../components/grid/schema/StoryGrid"

import "./category.styl"

const Stories = ({ stories, name }) => (
  <div id="story-list" className="main-page">
    <h2 className="section-title">Aktuelle Stories &uuml;ber '{name}'</h2>
    <Grid
      items={stories.filter(story => story.title)}
      render={story => <StoryGrid {...story} />}
    />
  </div>
)

const Products = ({ products, name }) => (
  <div id="product-list" className="main-page">
    <h2 className="section-title">{name}</h2>
    <Grid
      items={products.filter(product => product.name)}
      render={product => <ProductGrid {...product} />}
    />
  </div>
)

export default props => {
  const { type, categoryId } = props.pageContext
  const data = useStaticQuery(graphql`
    query {
      alloy {
        productCategories {
          _id

          products {
            _id
            categoriesIds
            name
            slug
            price
            quantity
            images {
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }

        storyCategories {
          _id

          stories {
            _id
            categoriesIds
            title
            slug
            cover {
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { productCategories, storyCategories } = data.alloy

  switch (type) {
    case "stories":
      return (
        <Stories 
          {...props.pageContext} 
          stories={storyCategories.find(story => story._id === categoryId).stories} 
        />
      )
    case "produkte":
      return (
        <Products
          {...props.pageContext}
          products={productCategories.find(product => product._id === categoryId).products}
        />
      )
    default:
      console.warn("Die Kategorie ist nicht definiert!")
  }

  // No Category type defined!
  useEffect(() => {
    navigate("/")
  }, [])
  return null
}
